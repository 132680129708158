html {
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --navbarHeight: 60px;
    --sidebarWidth: 210px;
    --sidebarWidthMin: 87px;
    --sidebarWidthMobile: 55px;
    --sidebarMargin: 0px;
    --sidebarCollapse: 1000px;
    --scrollbarWidth: 10px;
    --mhColor: #4682B4FF;
}

body {
    font-family: 'Inter', sans-serif !important;
}

.navbar-space {
    height: var(--navbarHeight);
}

.navbar-space-2 {
    height: 45px;
}

.bg-white {
    background-color: white;
    opacity: 1;
}
.bg-mh-dark {
    background-color: #183F37;
}

.bg-mh-light {
    background-color: #76FA92;
}

.bg-mh-light-50 {
    background-color: rgba(118, 250, 146, 0.75);
}

.bg-mh-dark-beige {
    background-color: #E1DED3;
}

.bg-mh-beige {
    background-color: #F9F7F1;
}

.bg-mh-grey {
    background-color: #DEDEDE;
}

.bg-mh-light-body {
    background-color: rgba(118, 250, 146, 0.1);
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-50 {
    opacity: 0.50 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-10 {
    opacity: 0.10 !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.border-mh-light {
    border-color: #76FA92 !important;
}

.border-mh-dark {
    border-color: #183F37 !important;
}

.border-mh-gray {
    border-color: #dee2e6 !important;
}

/*Style Scrollbar*/
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px lightgrey;
    border-radius: 0;
}
::-webkit-scrollbar-thumb {
    border-radius: 7px;
    -webkit-box-shadow: inset 0 0 5px darkgrey;
}
::-webkit-scrollbar:horizontal {
    display: none;
}

/*Style Scrollbar*/

@media (min-width: 0) {
    .text-watermark-1 {
        font-size: 4rem;
        line-height: 1;
    }
    .text-watermark-2 {
        font-size: 3.5rem;
        line-height: 1;
    }
    .text-watermark-3 {
        font-size: 3rem;
        line-height: 1;
    }
    .text-main-1 {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.4;
    }
    .text-main-2 {
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 1.2;
    }
    .text-main-3 {
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.2;
    }
    .text-main-4 {
        font-size: 1.1rem;
        font-weight: 300;
        line-height: 1.2;
    }
    .text-lead-1 {
        font-size: 1.05rem;
        font-weight: 300;
    }
    .text-lead-2 {
        font-size: 1.00rem;
        font-weight: 300;
    }
    .text-lead-3 {
        font-size: 0.97rem;
        font-weight: 300;
    }
    .text-paragraph {
        font-size: 1.00rem;
        font-weight: 400;
        line-height: 1.5
    }
    .text-app-1 {
        font-size: 2.5rem;
        line-height: 1.2;
    }
    .text-app-2 {
        font-size: 2.25rem;
        line-height: 1.2;
    }
    .text-app-3 {
        font-size: 2.0rem;
        line-height: 1.2;
    }
    .text-app-4 {
        font-size: 1.2rem;
        line-height: 1.2;
    }
    .text-app-5 {
        font-size: 1.2rem;
        line-height: 1.2;
    }
    .text-app-6 {
        font-size: 1.1rem;
        line-height: 1.2;
    }
    .text-app-7 {
        font-size: 1.0rem;
        line-height: 1.2;
    }
    .text-app-8 {
        font-size: 0.95rem;
        line-height: 1.2;
    }
    .text-app-9 {
        font-size: 0.9rem;
        line-height: 1.2;
    }
    .text-app-10 {
        font-size: 0.8rem;
        line-height: 1.2;
    }
    .text-app-11 {
        font-size: 0.7rem;
        line-height: 1.1;
    }
}

@media (min-width: 768px) {
    .text-watermark-1 {
        font-size: 4rem;
        line-height: 1;
    }
    .text-watermark-2 {
        font-size: 3.5rem;
        line-height: 1;
    }
    .text-watermark-3 {
        font-size: 3rem;
        line-height: 1;
    }
    .text-main-1 {
        font-size: 2.9rem;
        font-weight: 500;
        line-height: 1.4;
    }
    .text-main-2 {
        font-size: 2.3rem;
        font-weight: 300;
        line-height: 1.2;
    }
    .text-main-3 {
        font-size: 1.7rem;
        line-height: 1.2;
    }
    .text-main-4 {
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.2;
    }
    .text-lead-1 {
        font-size: 1.4rem;
        font-weight: 300;
    }
    .text-lead-2 {
        font-size: 1.2rem;
        font-weight: 300;
    }
    .text-lead-3 {
        font-size: 1.07rem;
        font-weight: 300;
    }
    .text-paragraph {
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 2
    }
    .text-app-1 {
        font-size: 2.5rem;
        line-height: 1.2;
    }
    .text-app-2 {
        font-size: 2.25rem;
        line-height: 1.2;
    }
    .text-app-3 {
        font-size: 2.0rem;
        line-height: 1.2;
    }
    .text-app-4 {
        font-size: 1.75rem;
        line-height: 1.2;
    }
    .text-app-5 {
        font-size: 1.5rem;
        line-height: 1.2;
    }
    .text-app-6 {
        font-size: 1.25rem;
        line-height: 1.2;
    }
    .text-app-7 {
        font-size: 1.15rem;
        line-height: 1.2;
    }
    .text-app-8 {
        font-size: 1.00rem;
        line-height: 1.2;
    }
    .text-app-9 {
        font-size: 0.95rem;
        line-height: 1.2;
    }
    .text-app-10 {
        font-size: 0.9rem;
        line-height: 1.2;
    }
    .text-app-11 {
        font-size: 0.75rem;
        line-height: 1.1;
    }
}

.rounded-xl {
    border-radius: 0.6rem !important;
}

.rounded-top-xl {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important;
}

.rounded-bottom-xl {
    border-bottom-left-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important;
}

.rounded-xxl {
    border-radius: 1rem !important;
}

.rounded-top-xxl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
}

.rounded-bottom-xxl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}

.row-spacer-1 {
    height: 75px;
}
.row-spacer-2 {
    height: 60px;
}
.row-spacer-3 {
    height: 45px;
}
.row-spacer-4 {
    height: 30px;
}

.lead-row-primary {
    color: #004085;
    background-color: rgba(204, 229, 255, 0.2);
    border-color: #b8daff;
}

.lead-row-warning {
    color: #594306;
    background-color: #FFF8D9;
    border-color: #ffeeba;
}

.mh-sidebar-xs {
    position: fixed;
    left: 0;
    width: var(--sidebarWidthMobile);
    font-size: 0.5rem;
    overflow: visible;
    z-index: 1040 !important;
    box-shadow: 0 0 0.25rem 0 rgba(11, 46, 19, 0.75);
}
.mh-app-xs {
    position: relative;
    padding-left: var(--sidebarWidthMobile);
    font-size: 0.8rem;
    font-weight: 400;
}

.mh-app-xs .mh-navbar-app {
    position: fixed;
    top: 0;
    left: calc(var(--sidebarWidthMobile) + var(--sidebarMargin));
    width: calc(100% - var(--sidebarWidthMobile) - var(--sidebarMargin));
    z-index: 1030;
    height: var(--navbarHeight);
}

.mh-sidebar-sm {
    position: fixed;
    left: 0;
    width: var(--sidebarWidthMin);
    font-size: 0.9rem;
    overflow: visible;
    z-index: 1040 !important;
    box-shadow: 0 0 0.25rem 0 rgba(11, 46, 19, 0.75);
}
.mh-app-sm {
    position: relative;
    padding-left: var(--sidebarWidthMin);
    font-size: 0.9rem;
    font-weight: 400;
}

.mh-app-sm .mh-navbar-app {
    position: fixed;
    top: 0;
    left: calc(var(--sidebarWidthMin) + var(--sidebarMargin));
    width: calc(100% - var(--sidebarWidthMin) - var(--sidebarMargin));
    z-index: 1030;
    height: var(--navbarHeight);
}

.mh-sidebar-normal {
    position: fixed;
    left: 0;
    width: var(--sidebarWidth);
    font-size: 0.9rem;
    overflow: visible;
    z-index: 1040 !important;
    box-shadow: 0 0 0.25rem 0 rgba(11, 46, 19, 0.75);
}
.mh-app-normal {
    position: relative;
    padding-left: var(--sidebarWidth);
    font-size: 0.9rem;
}
.mh-app-normal .mh-navbar-app {
    position: fixed;
    top: 0;
    left: calc(var(--sidebarWidth) + var(--sidebarMargin));
    width: calc(100% - var(--sidebarWidth) - var(--sidebarMargin));
    z-index: 1030;
    height: var(--navbarHeight);
}


/*Showing Sidebar minimized */
@media (min-width: 0) {
    .mh-sidebar {
        position: fixed;
        left: 0;
        width: var(--sidebarWidthMobile);
        font-size: 0.5rem;
        overflow: visible;
        z-index: 1040 !important;
        box-shadow: 0 0 0.25rem 0 rgba(11, 46, 19, 0.75);
    }
    .mh-app {
        position: relative;
        padding-left: var(--sidebarWidthMobile);
        font-size: 0.8rem;
        font-weight: 400;
    }
    .mh-app .mh-navbar-app {
        position: fixed;
        top: 0;
        left: calc(var(--sidebarWidthMobile) + var(--sidebarMargin));
        width: calc(100% - var(--sidebarWidthMobile) - var(--sidebarMargin));
        z-index: 1030;
        height: var(--navbarHeight);
    }
    .display-sidebar-min {
        display: inline-block;
    }
    .display-sidebar-normal {
        display: none;
    }
}

@media (min-width: 576px) {
    .mh-sidebar {
        position: fixed;
        left: 0;
        width: var(--sidebarWidthMin);
        font-size: 0.9rem;
        overflow: visible;
        z-index: 1049 !important;
    }
    .mh-app {
        position: relative;
        padding-left: var(--sidebarWidth);
        font-size: 0.9rem;
        font-weight: 400;
    }
    .mh-app .mh-navbar-app {
        position: fixed;
        top: 0;
        left: calc(var(--sidebarWidthMin) + var(--sidebarMargin));
        width: calc(100% - var(--sidebarWidthMin) - var(--sidebarMargin));
        z-index: 1030;
        height: var(--navbarHeight);
    }
    .display-sidebar-min {
        display: inline-block;
    }
    .display-sidebar-normal {
        display: none;
    }
}
/*Showing Sidebar Normal*/
@media (min-width: 1650px) {
    .mh-sidebar {
        position: fixed;
        left: 0;
        width: var(--sidebarWidth);
        font-size: 0.9rem;
        overflow: visible;
        z-index: 1040 !important;
    }
    .mh-app {
        position: relative;
        padding-left: var(--sidebarWidth);
        font-size: 0.9rem;
    }
    .mh-app .mh-navbar-app {
        position: fixed;
        top: 0;
        left: calc(var(--sidebarWidth) + var(--sidebarMargin));
        width: calc(100% - var(--sidebarWidth) - var(--sidebarMargin));
        z-index: 1030;
        height: var(--navbarHeight);
    }
    .display-sidebar-min {
        display: none;
    }
    .display-sidebar-normal {
        display: inline-block;
    }
}

/*Style sidebar nav link*/
.nav-app .nav-link {
    border-radius: 0 2.5rem 2.5rem 0;
    color: #1E4B42;
    font-weight: 400;
}

.nav-tabs .nav-link {
    color: #4e4e4e;
    font-weight: 400;
}

.nav-tabs .nav-link.active {
    color: #183F37;
    background-color: rgba(118, 250, 146, 0.65);
    border-radius: 0.75rem 0.75rem 0 0;
    box-shadow: 0.05rem 0.02rem 0.02rem 0.01rem rgba(24, 63, 55, 0.5) !important;
    border-bottom: 0;
    font-weight: 400;
}

.nav-tabs .nav-link.info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
    border-bottom: 0;
    font-weight: 400;
}

.nav-app .nav-link.notification {
    border-radius: 0 2.5rem 2.5rem 0;
    color: #dc3545;
    font-weight: 500;
}
.nav-app .nav-link.active,
.nav-app .show > .nav-link {
    color: #FFFFFF;
    background-color: rgba(30, 75, 66, 0.9);
    font-weight: 400;
    box-shadow: 0.15rem 0.1rem 0.2rem 0.2rem rgba(118,250,146,0.7) !important;
}
.nav-app .nav-link.active.notification,
.nav-app .show > .nav-link {
    color: #721c24;
    background-color: #f8d7da;
    font-weight: 500;
}

@media (min-width: 0) {
    .nav-icon {
        font-size: 1rem;
    }
    .topbar-icon {
        font-size: 1rem;
    }
}
@media (min-width: 576px) {
    .nav-icon {
        font-size: 1.25rem;
    }
    .topbar-icon {
        font-size: 1.21rem;
    }
}


.h-divider{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    height:1px;
    width:100%;
    border-top:1px solid #56585C;
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 0.7;
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 0.7;
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 0.7;
}

.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 0.7;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 0.7;
}


.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.drop-zone {
    color: #000000;
    background-color: #ffffff;
}

.drop-zone.active {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.drop-zone.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.drop-zone.error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.min-width-100 {
    min-width: 100px;
}
.min-width-200 {
    min-width: 200px;
}
.min-width-300 {
    min-width: 300px;
}
.min-width-400 {
    min-width: 400px;
}

.min-width-500 {
    min-width: 500px;
}
.min-width-600 {
    min-width: 600px;
}
.min-width-700 {
    min-width: 700px;
}

.max-width-80 {
    max-width: 80px;
}
.max-width-100 {
    max-width: 100px;
}
.max-width-150 {
    max-width: 150px;
}
.max-width-170 {
    max-width: 170px;
}
.max-width-200 {
    max-width: 200px;
}
.max-width-250 {
    max-width: 250px;
}
.max-width-275 {
    max-width: 275px;
}
.max-width-300 {
    max-width: 300px;
}
.max-width-325 {
    max-width: 325px;
}
.max-width-350 {
    max-width: 350px;
}
.max-width-375 {
    max-width: 375px;
}
.max-width-400 {
    max-width: 400px;
}
.max-width-500 {
    max-width: 500px;
}
.max-width-600 {
    max-width: 600px;
}
.max-width-700 {
    max-width: 700px;
}
.max-width-1000 {
    max-width: 1000px;
}


.listview {
    background-color: #fff;
    border-color: #383d41;
}

.listview:hover {
    background-color: rgba(226, 227, 229, 0.3);
    border-color: #383d41;
}

.listview.active {
    background-color: rgba(204, 229, 255, 0.5);
    border-color: #b8daff;
}

.listview.active:hover {
    background-color: rgba(204, 229, 255, 0.75);
    border-color: #b8daff;
}

.listview.success {
    background-color: rgba(212, 237, 218, 0.5);
    border-color: #c3e6cb;
}

.listview.success:hover {
    background-color: rgba(212, 237, 218, 0.75);
    border-color: #c3e6cb;
}

.listview.secondary {
    background-color: rgba(210, 210, 210, 0.6);
    border-color: #d6d8db;
}

.listview.secondary:hover {
    background-color: rgba(210, 210, 210, 0.8);
    border-color: #d6d8db;
}

.listview.warning {
    background-color: rgba(255, 243, 205, 0.6);
    border-color: #ffeeba;
}

.listview.warning:hover {
    background-color: rgba(255, 243, 205, 0.8);
    border-color: #ffeeba;
}

.listview.info {
    background-color: rgba(209, 236, 241, 0.6);
    border-color: #bee5eb;
}

.listview.info:hover {
    background-color: rgba(209, 236, 241, 0.8);
    border-color: #ffeeba;
}

.listview.danger-border {
    border: 1px solid #dc3545 !important;
}

.listview.success-border {
    border: 1px solid #008503 !important;
}

.listview.danger-border-light {
    border: 0.1rem solid rgba(255, 62, 80, 0.5) !important;
}

.listview.secondary-border-light {
    border: 0.1rem solid rgba(108, 117, 125, 0.5) !important;
}

.listview.success-border-light {
    border: 0.1rem solid rgba(1, 126, 4, 0.5) !important;
}

.border-success-light {
    border: 0.1rem solid rgba(1, 126, 4, 0.3) !important;
}

.border-warning-light {
    border: 0.1rem solid rgba(255, 144, 17, 0.3) !important;
}

.border-danger-light {
    border: 0.1rem solid rgba(255, 62, 80, 0.3) !important;
}

.border-primary-light {
    border: 0.1rem solid rgba(0, 76, 154, 0.3) !important;
}

.listview.danger {
    background-color: rgba(248, 215, 218, 0.6);
    border-color: #f5c6cb;
}
.listview.danger:hover {
    background-color: rgba(248, 215, 218, 0.8);
    border-color: #f5c6cb;
}

.text-alert-primary {
    color: #004c9a;
}

.text-alert-secondary {
    color: #383d41;
}

.text-alert-success {
    color: #155724;
}

.text-mh-dark {
    color: #1E4B42;
}

.text-mh-light {
    color: #76FA92;
}

.text-mh-dark-grey {
    color: #56585C
}

.shadow-mh-sm-light {
    box-shadow: 0 0.125rem 0.25rem rgba(24, 63, 55, 0.6) !important;
}



.shadow-mh-light {
    box-shadow: 0 0.5rem 1rem #183F37 !important;
}

.shadow-mh-lg-light {
    box-shadow: 0 1rem 3rem #183F37 !important;
}

.text-alert-info {
    color: #0c5460;
}

.text-alert-warning {
    color: #856404;
}

.text-alert-danger {
    color: #721c24;
}

.popover {
    max-width: 500px;
}

.modal-menu {
    z-index: 2000;
}

@media (min-width: 0) {
    .modal-xxl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .modal-xxl {
        max-width: 1380px;
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.table-modal {
    table-layout: fixed;
    word-wrap: break-word;
}

.table-modal th,
.table-modal td {
    padding: 0.2rem;
}
.table-modal thead th{
    background-color: rgba(70, 130, 180, 0.75);
    font-size: 14px;
    color: white;
}
.table-modal thead td{
    font-size: 14px;
}

.table-modal tbody{
    font-size: 14px;
}

.max-height-300 {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.max-height-350 {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}

.max-height-400 {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.max-height-450 {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
}

.max-height-500 {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.max-height-550 {
    max-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
}

.max-height-600 {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}

.max-height-650 {
    max-height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
}

.max-height-700 {
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
}

.max-height-750 {
    max-height: 750px;
    overflow-y: auto;
    overflow-x: hidden;
}

.min-height-500 {
    min-height: 500px;
}

.min-height-400 {
    min-height: 400px;
}

.min-height-900 {
    min-height: 900px;
}

.mapMarker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35px;
    height: 35px;
    background-color: steelblue;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: white;
}

.mapMarker:hover {
    z-index: 1;
}

.mapMarker.highlight {
    background-color: #ffd301;
    z-index: 1;
}

.mapMarker.active {
    background-color: rgba(255, 0, 0, 1);
    z-index: 1;
}

/* The switch - the box around the slider */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 1rem;
    margin: 0 0 0 0;
}

/* Hide default HTML checkbox */
.toggle-switch input {
    visibility: hidden;
    width: 0;
    height: 0;
}

/* The slider */
.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 1rem;
    -webkit-transition: .1s;
    transition: .1s;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 0.7rem;
    width: 0.7rem;
    left: 0.15rem;
    bottom: 0.15rem;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: .1s;
    transition: .1s;
}

input:checked + .toggle-slider {
    background-color: rgba(33, 150, 243, 0.9);
}

input:checked:disabled + .toggle-slider {
    background-color: rgba(33, 150, 243, 0.5);
}

input:checked + .toggle-slider:before {
    -webkit-transform: translateX(1rem);
    -ms-transform: translateX(1rem);
    transform: translateX(1rem);
}

.border-danger {
    border: 1px solid #dc3545 !important;
}

.border-primary {
    border: 1px solid #004c9a !important;
}

.border-success {
    border: 1.5px solid #008503 !important;
}

.border-secondary {
    border: 1.5px solid rgba(108, 117, 125, 0.5) !important;
}

.border-secondary-light {
    border: 0.1rem solid rgba(108, 117, 125, 0.3) !important;
}

.center-cropped {
    background-position: center center;
    background-repeat: no-repeat;
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.text-pre-line {
    white-space: pre-line;
}

.pdf-watermark {
    position: absolute;
    transform: rotate(340deg);
    -webkit-transform: rotate(340deg);
    opacity: 0.25;
}

.d-display-print {
    display: none;
}

@media print {
    @page {
        size: 1800px 1200px;
        margin: 50px;
    }

    .d-display-print {
        display: block;
    }
    .d-none-print {
        display: none;
    }
    .pdf-container {
        display: none;
    }
    .pdf-page {
        page-break-after: always;
        height: 95%;
    }
}

.btn-display {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn-display {
        transition: none;
    }
}

.btn-display-disabled {
    opacity: 0.75;
}

.btn-display-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-display-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-display-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-display-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-display-warning {
    color: #fff;
    background-color: rgba(255, 144, 17, 0.85);
    border-color: #ffb007;
}

.btn-display-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-display-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-display-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-display-outline-primary {
    color: #007bff;
    border-color: #007bff;
}

.btn-display-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
}

.btn-display-outline-success {
    color: #28a745;
    border-color: #28a745;
}

.btn-display-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
}

.btn-display-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
}

.btn-display-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
}

.btn-display-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-display-outline-dark {
    color: #343a40;
    border-color: #343a40;
}

.btn-display-lg, .btn-display-group-lg > .btn-display {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-display-sm, .btn-display-group-sm > .btn-display {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-display-block {
    display: block;
    width: 100%;
}

.btn-display-block + .btn-display-block {
    margin-top: 0.5rem;
}

.badge-display-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem !important;
}

@media (min-width: 1200px) {
    .modal-xxl {
        max-width: 100%;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.alert-disabled {
    background-color: #E9ECEF;
    border-color: #CED4DA;
}

.clickable {
    cursor: pointer;
}

.rounded-pill-left {
    border-radius: 50rem 0 0 50rem !important;
}
.rounded-pill-right {
    border-radius: 0 50rem 50rem 0 !important;
}

.hover-popup-wrapper {
    position: relative;
}

.popup-wrapper {
    position: relative;
}

.hover-popup {
    position: absolute;
    background-color: #ffffff;
    border: 0.1rem solid #ccc;
    width: max-content;
    max-width: 100%;
    min-width: min-content;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    color: #4e4e4e;
    z-index: 1050;
    box-shadow: 0 0.2rem 0.5rem 0.3rem rgba(78, 78, 78, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    word-wrap: break-word;
}

.hover-popup-top,
.hover-popup-bottom {
    left: 50%;
    transform: translateX(-50%);
}

.hover-popup-top {
    bottom: 100%;
    margin-bottom: 0.2rem;
}

.hover-popup-bottom {
    top: 100%;
    margin-top: 0.2rem
}

.hover-popup-left,
.hover-popup-right {
    top: 50%;
    transform: translateY(-50%);
}

.hover-popup-left {
    right: 100%;
    margin-right: 0.2rem;
}

.hover-popup-right {
    left: 100%;
    margin-left: 0.2rem;
}

.hover-popup-wrapper:hover .hover-popup {
    opacity: 1;
    visibility: visible;
    z-index: 1050;
}

.popup-wrapper .hover-popup {
    opacity: 1;
    visibility: visible;
    z-index: 1050;
}

.position-initial1 {
    z-index: 9999999 !important;
}

.modal-content {
    border-radius: 1.25rem !important;
    border: 0.1rem solid rgba(108, 117, 125, 0.5) !important;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.10), 0 6px 20px rgba(255, 255, 255, 0.10) !important;
}

.modal-header {
    border-radius: 1rem 1rem 0 0 !important;
}

.letter-spacing-1 {
    letter-spacing: 0.25rem;
}

.sticky-top-table {
    top: var(--navbarHeight);
    width: calc(100% + 30px);
    margin: 0 -15px;
    padding: 5px 15px 0;
    gap: 0.5rem;
}
